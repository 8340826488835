import { inject, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ManualClienteCadastro } from '../../../model/controles/manuais-clientes/manual-cliente-cadastro';
import { ManualClienteConsulta } from '../../../model/controles/manuais-clientes/manual-cliente-consulta';
import { ManualClienteAprovador } from '../../../model/controles/manuais-clientes/manual-cliente-aprovador';
import { ManualClienteRequisito } from '../../../model/controles/manuais-clientes/manual-cliente-requisito';
import { AprovacoesPendentes } from '../../../model/controles/manuais-clientes/aprovacoes-pendentes';

@Injectable({
  providedIn: 'root'
})
export class ManuaisClientesService {

  urlApi = `${environment.url}/controles/norma-cliente`;
  http = inject(HttpClient);

  public consultarManuais(page: number, size: number): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/${page}/${size}`);
  }

  public consultarManualPorId(idManual: any): Observable<any> {
    return this.http.get<any>(`${this.urlApi}/${idManual}`);
  }

  public cadastrarManual(manual: ManualClienteCadastro): Observable<ManualClienteConsulta> {
    return this.http.post<ManualClienteConsulta>(`${this.urlApi}`, manual);
  }

  public alterarManual(manual: ManualClienteCadastro): Observable<ManualClienteConsulta> {
    return this.http.put<ManualClienteConsulta>(`${this.urlApi}`, manual);
  }

  public incluirAprovador(idNorma: any, aprovador: ManualClienteAprovador): Observable<ManualClienteConsulta> {
    return this.http.post<ManualClienteConsulta>(`${this.urlApi}/aprovador/${idNorma}`, aprovador);
  }

  public excluirAprovador(idAprovador: any): Observable<any> {
    return this.http.delete<any>(`${this.urlApi}/aprovador/${idAprovador}`);
  }

  public incluirArquivo(idNorma: any, arquivo: FormData): Observable<any>{
    return this.http.post<any>(`${this.urlApi}/arquivo/${idNorma}`, arquivo);
  }

  public excluirArquivo(idArquivo: any): Observable<any>{
    return this.http.delete<any>(`${this.urlApi}/arquivo/${idArquivo}`);
  }

  public excluir(idArquivo: any): Observable<any>{
    return this.http.delete<any>(`${this.urlApi}/arquivo/${idArquivo}`);
  }

  public incluirRequisito(idNorma: any, requisito: ManualClienteRequisito): Observable<ManualClienteConsulta>{
    return this.http.post<ManualClienteConsulta>(`${this.urlApi}/requisito/${idNorma}`, requisito);
  }

  public alterarRequisito(requisito: ManualClienteRequisito): Observable<ManualClienteConsulta>{
    return this.http.put<ManualClienteConsulta>(`${this.urlApi}/requisito`, requisito);
  }

  public consultaRequisitoPorId(idRequisito: any): Observable<any>{
    return this.http.get<any>(`${this.urlApi}/requisito/${idRequisito}`);
  }

  public excluirRequisito(idRequisito: any): Observable<any>{
    return this.http.delete<any>(`${this.urlApi}/requisito/${idRequisito}`);
  }

  public consultarAprovacoesPendentes(): Observable<AprovacoesPendentes[]>{
    return this.http.get<AprovacoesPendentes[]>(`${this.urlApi}/aprovacoes-pendentes`);
  }

  public aprovarManulDoCliente(idManual: any, idAprovacao: any): Observable<any>{
    return this.http.put<any>(`${this.urlApi}/aprovar/${idManual}/${idAprovacao}`, null);
  }
}
